import { List, Stack, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";

import { Type_index_taskAreaProductType } from "src/api/tms-scheduling/taskAreaProductTypes/types";
import { useIndexProductTypeTask } from "src/api/tms-scheduling/taskProductTypes";
import { Type_index_productTypeTasks } from "src/api/tms-scheduling/taskProductTypes/types";
import { Empty } from "src/components";
import { ItemChildrenProps } from "src/components/Components_Common/accordions/Accordion";
import { FullSpinner } from "src/components/Components_Common/Spinner/FullSpinner";
import { Type_event_productTypesTasks, useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { useContextualDrawer } from "src/layouts/Layout_ContextualDrawer/Provider_ContextualDrawer";

export const ProductTypesList = memo(
    ({
        products,
    }: {
        products:
            | Type_index_productTypeTasks[]
            | Type_index_taskAreaProductType[];
    }) => {
        return (
            <List>
                {products.map((product) => (
                    <Stack
                        key={`task_productType_${product.id}`}
                        direction={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        data-testid={`Task-ProductType-${product.id}`}
                    >
                        <Typography
                            data-testid={`Task-ProductType-Name-${product.id}`}
                        >
                            {product.productType.name}
                        </Typography>
                        <Typography
                            data-testid={`Task-ProductType-Quantity-${product.id}`}
                        >
                            ({product.quantity}
                            {product.productType.unit.name})
                        </Typography>
                    </Stack>
                ))}
            </List>
        );
    },
);

ProductTypesList.displayName = "ProductTypesList";

type Type_Props_ProductTypesTask = ItemChildrenProps & {
    taskId: number;
};

export const ProductTypesTask = ({
    taskId,
    readonly,
}: Type_Props_ProductTypesTask) => {
    const [products, setProducts] = useState<Type_index_productTypeTasks[]>([]);
    const { formatMessageWithPartialKey: fmt } = useCoreIntl(
        "Project.Views.Planning.DrawerTasks.ProductType",
    );
    const { openPaper } = useContextualDrawer();

    const { data, isFetching: isFetchingProducts } = useIndexProductTypeTask(
        taskId,
        "list",
    );

    useEffect(() => {
        if (data && !isFetchingProducts) {
            setProducts(data);
        }
    }, [isFetchingProducts]);

    useChannel({
        eventHandler: ({ event, data }) => {
            const productTypesTasksData = data as Type_event_productTypesTasks;
            if (event === "updateProductTypesTasks") {
                setProducts(productTypesTasksData);
            }
        },
    });

    const handleNewProductType = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        openPaper("productType", {
            id: taskId,
            focus: true,
        });
    };

    return isFetchingProducts ? (
        <FullSpinner />
    ) : products.length > 0 ? (
        <ProductTypesList products={products} />
    ) : (
        <Empty
            message={fmt("EmptyProductTypeMessage")}
            {...(!readonly && {
                actionMessage: fmt("AddFirstProductType"),
                onClick: handleNewProductType,
            })}
            dataTestIdRef={"ProductType"}
            sx={{ mb: 2 }}
        />
    );
};
