import { Stack } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-premium";
import { useMemo } from "react";

import { useIndexPeopleTaskAreaForMatrix } from "src/api/tms-scheduling/peopleTaskArea";
import { Matrix } from "src/components/Components_Common/matrix/Matrix";
import { getMaxDepth } from "src/components/Components_Scheduling/Matrix/helpers/MatrixSequenceAreasHelpers";
import { getTaskAreaRows } from "src/components/Components_Scheduling/Matrix/helpers/MatrixTaskAreasHelper";
import { MatrixSequenceAreasColumns } from "src/components/Components_Scheduling/Matrix/MatrixSequenceAreas/MatrixSequenceAreasColumns";
import { useUser } from "src/contexts/user";
import { useCoreIntl } from "src/hooks/useCoreIntl";

type Type_Props_MatrixAssignees = {
    taskId: number;
    onClose: () => void;
};

export const MatrixAssignees = ({
    taskId,
    onClose,
}: Type_Props_MatrixAssignees) => {
    const page = "tasks";

    ///////////////////////////////////////
    ///           Permission            ///
    ///////////////////////////////////////
    const { checkPermission, user } = useUser();

    const _editable = useMemo(() => {
        return page
            ? checkPermission(page, "create") || checkPermission(page, "update")
            : true;
    }, [page, user]);

    ///////////////////////////////////////
    ///          Fetched data           ///
    ///////////////////////////////////////
    const { data: peopleTaskAreas } = useIndexPeopleTaskAreaForMatrix({
        taskId,
    });

    const maxDepth = useMemo(
        () => (peopleTaskAreas ? getMaxDepth(peopleTaskAreas.areas) : 0),
        [peopleTaskAreas],
    );

    ///////////////////////////////////////
    ///             i18                 ///
    ///////////////////////////////////////
    const { formatMessageWithPartialKey: _fmtTableColumn } =
        useCoreIntl("Table.Column");

    ///////////////////////////////////////
    ///          Columns                ///
    ///////////////////////////////////////
    const areaColumns: GridColDef[] = [
        ...MatrixSequenceAreasColumns({
            depth: maxDepth,
            enableOrderEdition: false,
            withSelected: false,
            withOrder: false,
        }),
    ];

    const dataColumns: GridColDef[] = useMemo(() => [], []);

    const columns = [...areaColumns, ...dataColumns];

    ///////////////////////////////////////
    ///              Rows               ///
    ///////////////////////////////////////
    const dataRows = getTaskAreaRows({
        data: peopleTaskAreas,
        dataColumns: dataColumns,
        maxDepth: maxDepth,
    });

    return (
        <Stack p={4} flexGrow={1}>
            <Matrix columns={columns} rows={dataRows} onClose={onClose} />
        </Stack>
    );
};
