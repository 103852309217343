import { Stack, Typography } from "@mui/material";
import {
    DataGridPremiumProps,
    GridCell,
    GridCellParams,
    GridRow,
    GridValidRowModel,
} from "@mui/x-data-grid-premium";
import React, {
    Dispatch,
    memo,
    SetStateAction,
    useEffect,
    useState,
} from "react";
import { QueryObserverResult, UseMutateAsyncFunction } from "react-query";

import { TMC_Button } from "src/components/Components_Common/_MuiComponentsVariants";
import { OverlayCircularProgress } from "src/components/Components_Common/CircularProgress/CircularProgress";
import { Styled_Matrix } from "src/components/Components_Common/matrix/Matrix.style";
import { useChannel } from "src/hooks/useChannel";
import { useCoreIntl } from "src/hooks/useCoreIntl";

// WIDTHS AND SIZES
const LINE_HEIGHT = 32;
const ROW_HEIGHT = 42;

export const getDifferentAttributes = (
    newValue: GridValidRowModel,
    oldValue: GridValidRowModel,
): GridValidRowModel => {
    const diff = Object.keys(newValue).reduce((differences, key) => {
        if (oldValue[key] !== newValue[key]) {
            differences[key] = newValue[key];
        }
        return differences;
    }, {} as GridValidRowModel);

    // id doit toujours être présent
    if (Object.keys(diff).length) diff.id = newValue.id;

    return diff;
};

const defaultMutateUpdate: UseMutateAsyncFunction<
    any,
    any,
    any,
    unknown
> = async () => {
    console.warn(
        "Default mutateUpdate executed. Please provide your own implementation.",
    );
    return undefined; // Default behavior
};

const handleSaveDefault = async (
    gridData: GridValidRowModel[],
    setProgressBarValue: Dispatch<SetStateAction<number>>,
    mutateUpdate: UseMutateAsyncFunction<any, any, any, unknown>,
    keysToUpdateSolo?: string[],
) => {
    const totalRows = gridData.length;
    const stepValue = 100 / totalRows;

    let mutations: { id: number; data: any }[] = [];

    for (const elem of gridData) {
        const tmp = { ...elem };

        if (keysToUpdateSolo?.length) {
            keysToUpdateSolo.forEach((key) => {
                Object.keys(tmp).forEach((k) => {
                    if (k.includes(key)) {
                        mutations.push({
                            id: elem.id,
                            data: { [k]: tmp[k] },
                        });
                        delete tmp[k];
                    }
                });
            });

            for (const mutation of mutations) {
                await mutateUpdate(mutation);
            }

            mutations = [];
        }

        if (Object.keys(tmp).length > 1) {
            await mutateUpdate({ id: elem.id, data: tmp });
        }

        setProgressBarValue((prev) => prev + stepValue);
    }
};

export type Type_Props_MemoizedDataGridPremium = DataGridPremiumProps & {
    mutateUpdate?: UseMutateAsyncFunction<any, any, any, unknown>;
    height?: string | number;
    refetch?: () => Promise<QueryObserverResult<any, any>>;
    processRowUpdateAdditionalBehaviors?: (
        newRow: GridValidRowModel,
        oldRow: GridValidRowModel,
    ) => GridValidRowModel; // Remplace la methode qui récupère les différences dans processRowUpdate
    isSaveDisabled?: boolean; // pour désactiver le bouton lorsque le tableau est invalide
    handleSave?: (
        gridData: GridValidRowModel[],
        setProgressBarValue: Dispatch<SetStateAction<number>>,
        handleSubmit: UseMutateAsyncFunction<any, any, any, unknown>,
        keysToUpdateSolo?: string[],
    ) => Promise<any[] | any>;
    onClose?: () => void;
    gridDataLayer?: GridValidRowModel[];
    keysToUpdateSolo?: string[];
};

const MemoizedDataGridPro = ({
    height,
    columns,
    mutateUpdate = defaultMutateUpdate, // qui vient du composant métier, et appeler pour le save
    refetch,
    processRowUpdateAdditionalBehaviors,
    isSaveDisabled = false, // TODO pouvoir agir sur l'état du bouton save
    handleSave = handleSaveDefault,
    gridDataLayer,
    onClose,
    keysToUpdateSolo,
    ...props
}: Type_Props_MemoizedDataGridPremium) => {
    //i18n
    const { formatMessageWithPartialKey: fmtActions } = useCoreIntl("Actions");

    // states
    const [gridData, setGridData] = useState<GridValidRowModel[]>([]);
    const [progressBarValue, setProgressBarValue] = useState<number>(0);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [updatedCells, setUpdatedCells] = useState<Set<string>>(new Set());

    const { sendEvent } = useChannel({});

    useEffect(() => {
        if (gridDataLayer) {
            setGridData((prev) => {
                const prevDataMap = new Map(prev.map((row) => [row.id, row]));
                gridDataLayer.forEach((newRow) => {
                    prevDataMap.set(newRow.id, {
                        ...prevDataMap.get(newRow.id),
                        ...newRow,
                    });
                    const cellKeys = Object.keys(newRow).map(
                        (field) => `${newRow.id}-${field}`, // Combine row ID and field name to form a unique key.
                    );
                    setUpdatedCells((prev) => new Set([...prev, ...cellKeys]));
                });
                return Array.from(prevDataMap.values());
            });
        }
    }, [gridDataLayer]);

    // utils
    const handleSaveAll = async () => {
        try {
            setIsSubmitting(true);
            setProgressBarValue(0);

            await handleSave(
                gridData,
                setProgressBarValue,
                mutateUpdate,
                keysToUpdateSolo,
            );

            refetch?.();
            onClose?.();
        } catch (error) {
            console.error("handleSaveAll", error);
        } finally {
            sendEvent("updatePlanningMatrix");
            setIsSubmitting(false);
        }
    };

    /*
     * - Gestion de l'édition au on blur
     */

    const processRowUpdate = async (
        newRow: GridValidRowModel,
        oldRow: GridValidRowModel,
    ) => {
        let hasReset = false;

        if (gridData.length && gridData.length === progressBarValue) {
            setProgressBarValue(0);
            setGridData(() => []);
            hasReset = true;
        }

        let diff: GridValidRowModel | null = null;

        if (processRowUpdateAdditionalBehaviors) {
            diff = processRowUpdateAdditionalBehaviors(newRow, oldRow);
            newRow = { ...newRow, ...diff };
        } else {
            diff = getDifferentAttributes(newRow, oldRow);
        }

        // If there are no differences, return the new row as-is.
        if (!Object.keys(diff).length) return newRow;

        // Generate unique keys for each updated cell in the row.
        const cellKeys = Object.keys(diff).map(
            (field) => `${newRow.id}-${field}`, // Combine row ID and field name to form a unique key.
        );
        setUpdatedCells((prev) => new Set([...prev, ...cellKeys]));

        // Check if a row with the same ID as `diff` already exists in the grid data.
        const exist = gridData.find((elem) => elem.id === diff?.id);
        if (exist && !hasReset) {
            setGridData((prevData) =>
                prevData.map((row) =>
                    row.id === newRow.id ? { ...row, ...diff } : row,
                ),
            );
        } else {
            setGridData((prevData) => [...prevData, diff as GridValidRowModel]);
        }

        // Permet d Activer le Save après l'édition
        isEditing && setIsEditing(false);

        return newRow;
    };

    const getCellClassName = (params: GridCellParams) => {
        const cellKey = `${params.id}-${params.field}`;
        return updatedCells.has(cellKey) ? "teamoty-cell-updated" : "";
    };

    return (
        <Stack
            flexGrow={1}
            height={height ?? "auto"}
            data-testid={`Matrix-Container`}
            width="100%"
            sx={{
                overflowY: "hidden",
                overflowX: "auto",
            }}
            justifyContent="space-between"
        >
            <Styled_Matrix
                {...props}
                processRowUpdate={props.processRowUpdate ?? processRowUpdate}
                cellSelection
                // Permet de Désactiver le Save lors de l'édition
                // Le CTA Sava est réactiver dans la fonction processRowUpdate
                onCellEditStart={() => {
                    setIsEditing(true);
                }}
                columns={columns.map((col) => ({
                    ...col,
                    cellClassName: getCellClassName,
                }))}
                getRowHeight={() => ROW_HEIGHT}
                loading={props.loading || isSubmitting || progressBarValue > 0}
                columnHeaderHeight={LINE_HEIGHT}
                // Global Props
                disableRowSelectionOnClick={true}
                disableColumnSorting={props.disableColumnSorting ?? true} // désactive le tri de colonne
                disableColumnResize={props.disableColumnResize ?? false} // active le redimensionnement de colonne
                disableColumnMenu={props.disableColumnMenu ?? true} // Cache le menu dans l'entête des colonnes
                hideFooter={true} // cache le footer qui affiche juste le nombre d'éléments par défaut et la pagination
                ignoreValueFormatterDuringExport={true} // Récupération de la valeur brute du champ et non celle du visuelle
                defaultGroupingExpansionDepth={-1} // permet d'empêcher le tableau de replier la hierarchy lorsque l'on copie une cellule
                // Custom cell and row props
                slots={{
                    loadingOverlay: () => (
                        <OverlayCircularProgress value={progressBarValue} />
                    ),
                    ...props.slots,
                    cell: (props) => (
                        <GridCell
                            {...props}
                            data-testid={`cell-${props.rowId}-${props.column.field}`}
                        />
                    ),
                    row: (props) => (
                        <GridRow
                            {...props}
                            data-testid={`row-${props.rowId}`}
                        />
                    ),
                }}
            />
            <Stack alignItems={"end"} justifyContent={"center"}>
                <TMC_Button
                    type="button"
                    variant="contained"
                    onClick={handleSaveAll}
                    style={{ height: "auto" }}
                    disabled={
                        isEditing ||
                        isSaveDisabled ||
                        isSubmitting ||
                        !gridData.length ||
                        progressBarValue > 0
                    }
                >
                    <Typography>{fmtActions("Save")}</Typography>
                </TMC_Button>
            </Stack>
        </Stack>
    );
};

export const Matrix = memo(MemoizedDataGridPro);
