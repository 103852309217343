import { FormikValues } from "formik";
import * as React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ReportKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateReport,
    formatterIndexReport,
    formatterIndexReports,
    formatterShowReport,
    formatterUpdateReport,
} from "src/api/tms-scheduling/reportTemplate/formatters";
import {
    createReportTemplate,
    deleteReportTemplate,
    downloadReportTemplate,
    indexReportTemplates,
    showReportTemplate,
    statusReportTemplate,
    updateReportTemplate,
} from "src/api/tms-scheduling/reportTemplate/services";
import {
    Type_put_reportTemplate,
    Type_sch_index_reportTemplate,
    Type_sch_put_reportTemplate,
    Type_sch_show_reportTemplate,
    Type_show_reportTemplate,
} from "src/api/tms-scheduling/reportTemplate/types";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";
import { downloadBlobFile } from "src/utils/file";

export const useIndexReportTemplate = (uniqueKey: string = "") => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [ReportKeys.INDEX, requestConfig, uniqueKey],
        queryFn: () => indexReportTemplates(requestConfig),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: useIndexReport");
            }
            return formatterIndexReports(
                data?.data?.data as Type_sch_index_reportTemplate[],
            );
        },
    });
};

export const useShowReportTemplate = (
    reportId: number,
    uniqueKey: string = "",
) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [ReportKeys.SHOW, requestConfig, reportId, uniqueKey],
        queryFn: () => showReportTemplate(requestConfig, reportId),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!reportId,
        refetchOnWindowFocus: false,
        select: (data): Type_show_reportTemplate => {
            if (!data?.success) {
                throw new Error("Wrong format data: useShowReport");
            }
            return formatterShowReport(
                data?.data?.data as Type_sch_show_reportTemplate,
            );
        },
    });
};

export const useDownloadReportTemplate = (reportId: number | null) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [ReportKeys.SHOW, requestConfig, reportId],
        queryFn: () =>
            reportId && downloadReportTemplate(requestConfig, reportId),
        enabled:
            !!requestConfig.projectId &&
            !!requestConfig.subProjectId &&
            !!reportId,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success) {
                throw new Error("Wrong format data: useShowReport");
            }
            downloadBlobFile(
                data?.data,
                `${Date.now()}_report_template.docx`,
                data?.headers["content-type"],
            );
        },
    });
};

export const mutationCreateReportTemplate = (callback?: any) => {
    const { requestConfig } = useProject();
    const { formatMessageWithPartialKey: fmt } = useCoreIntl("Drawer.DayOff");
    const { formatMessageWithPartialKey: fmtErr } = useCoreIntl("Errors");
    const { addSuccess, addWarning } = useToast();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newReport: FormikValues) =>
            createReportTemplate(
                formatterCreateReport(newReport),
                requestConfig,
            ),
        onSuccess: async (data: any) => {
            if (!data?.success) {
                throw new Error("Wrong format data: mutationCreateReport");
            }

            const formattedData = formatterIndexReport(data.data.data);

            addSuccess({
                description: fmt("ToastSuccess", {
                    values: {
                        b: (chunks: string) => <b>{chunks}</b>,
                        dayOff: formattedData.name,
                    },
                }),
            });
            if (typeof callback === "function") {
                callback();
            } else {
                await queryClient.invalidateQueries([
                    ReportKeys.INDEX,
                    requestConfig,
                ]);
            }
            return data;
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErr("GenericError"),
            });
            console.error("ERROR mutationCreateReport", err);
            return err;
        },
    });
};

export const mutationUpdateReportTemplate = (callback?: any) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (newReport: Type_put_reportTemplate) => {
            const formattedReportDataForUpdate: Type_sch_put_reportTemplate =
                formatterUpdateReport(newReport);

            return updateReportTemplate(
                newReport.id,
                formattedReportDataForUpdate,
                requestConfig,
            );
        },
        onSuccess: async (data: any) => {
            await queryClient.invalidateQueries([
                ReportKeys.INDEX,
                requestConfig,
            ]);
            if (callback) callback(formatterIndexReport(data.data.data));
        },
        onError: (err: any) => {
            return err;
        },
    });
};

export const mutationStatusReportTemplate = (callback?: any) => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (report: Type_put_reportTemplate) => {
            return statusReportTemplate(report.id, report, requestConfig);
        },
        onSuccess: async (data: any) => {
            if (!data?.success) {
                throw new Error("Error format data: mutationStatusReport");
            }
            await queryClient.invalidateQueries([
                ReportKeys.INDEX,
                requestConfig,
            ]);
            if (callback) callback(formatterIndexReport(data.data.data));
        },
    });
};

export const mutationDeleteReportTemplate = () => {
    const { requestConfig } = useProject();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (id: number) => {
            return deleteReportTemplate(id, requestConfig);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries([
                ReportKeys.INDEX,
                requestConfig,
            ]);
        },
    });
};
