import { useMutation, useQuery } from "react-query";

import { Type_AppRequestConfig } from "src/api/fetch";
import { TaskAreaProductTypeKeys } from "src/api/tms-scheduling/keys";
import {
    formatterCreateTaskAreaProductType,
    formatterCreateTaskAreaProductTypeForMatrix,
    formatterIndexTaskAreaProductType,
    formatterIndexTaskAreaProductTypesMatrix,
} from "src/api/tms-scheduling/taskAreaProductTypes/formatters";
import {
    createOrUpdateTaskAreaProductTypes,
    indexTaskAreaProductTypes,
    indexTaskAreaProductTypesMatrix,
} from "src/api/tms-scheduling/taskAreaProductTypes/services";
import {
    Type_index_taskAreaProductType,
    Type_post_taskAreaProductType,
    Type_post_taskAreaProductType_forFormatter,
    Type_post_taskAreaProductType_forMatrix,
    Type_sch_post_taskAreaProductType,
} from "src/api/tms-scheduling/taskAreaProductTypes/types";
import { NO_STALE_TIME, Type_stale_times } from "src/configurations/app";
import { useProject } from "src/contexts/project";
import { useToast } from "src/contexts/toasts";
import { useCoreIntl } from "src/hooks/useCoreIntl";

export const useIndexTaskAreaProductTypes = (
    taskAreaConfig: Type_AppRequestConfig,
    staleTime: Type_stale_times = NO_STALE_TIME,
) => {
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");
    const { requestConfig } = useProject();
    const config = { ...requestConfig, ...taskAreaConfig };

    return useQuery({
        queryKey: [TaskAreaProductTypeKeys.INDEX, config],
        queryFn: ({ signal }) => indexTaskAreaProductTypes(config, signal),
        enabled:
            !!config.projectId &&
            !!config.subProjectId &&
            !!config.versionId &&
            !!config.taskAreaId,
        refetchOnWindowFocus: false,
        select: (data): Type_index_taskAreaProductType[] => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useIndexTaskAreaProductTypes",
                );
            }

            return formatterIndexTaskAreaProductType(data.data.data);
        },
        onError: (err) => {
            console.error(fmtErrors("GenericError", {}), err);
            addWarning({ description: fmtErrors("GenericError", {}) });
            return err;
        },
        staleTime: staleTime,
    });
};

export const useIndexTaskAreaProductTypesMatrix = (taskId: number) => {
    const { requestConfig } = useProject();

    return useQuery({
        queryKey: [
            TaskAreaProductTypeKeys.INDEX_FOR_MATRIX,
            requestConfig,
            taskId,
        ],
        queryFn: () =>
            indexTaskAreaProductTypesMatrix({
                ...requestConfig,
                taskId: taskId,
            }),
        enabled: !!requestConfig.projectId && !!requestConfig.subProjectId,
        refetchOnWindowFocus: false,
        select: (data) => {
            if (!data?.success || !data?.data?.data) {
                throw new Error(
                    "Wrong format data: useIndexTaskAreaProductTypesMatrix",
                );
            }
            return formatterIndexTaskAreaProductTypesMatrix(data.data.data);
        },
    });
};

export const mutationUpsertTaskAreaProductTypes = (
    taskAreaConfig: Type_AppRequestConfig,
    forMatrix: boolean = true,
) => {
    const { requestConfig } = useProject();
    const { addWarning } = useToast();
    const { formatMessageWithPartialKey: fmtErrors } = useCoreIntl("Errors");

    return useMutation({
        mutationFn: (
            productType:
                | Type_post_taskAreaProductType_forMatrix
                | Type_post_taskAreaProductType,
        ) => {
            // get the right formatter
            const formattedProductType = (
                productType:
                    | Type_post_taskAreaProductType_forMatrix
                    | Type_post_taskAreaProductType,
                forMatrix: boolean,
            ): Type_sch_post_taskAreaProductType => {
                if (forMatrix) {
                    return formatterCreateTaskAreaProductTypeForMatrix(
                        productType as Type_post_taskAreaProductType_forFormatter,
                    );
                } else {
                    return formatterCreateTaskAreaProductType(
                        productType as Type_post_taskAreaProductType,
                    );
                }
            };

            return createOrUpdateTaskAreaProductTypes(
                formattedProductType(productType, forMatrix),
                {
                    ...requestConfig,
                    ...taskAreaConfig,
                    ...("id" in productType ? { areaId: productType.id } : {}), // Matrix only : areaId is the id unique of the row in matrix
                },
            );
        },
        onSuccess: (data): void => {
            if (!data?.success) {
                throw new Error(
                    "Wrong format data: mutationUpsertTaskAreaProductTypes",
                );
            }
        },
        onError: (err: any) => {
            addWarning({
                description: fmtErrors("GenericError"),
            });
            return err;
        },
    });
};
