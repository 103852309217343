import { FormikValues } from "formik";

import {
    Type_index_reportTemplate,
    Type_put_reportTemplate,
    Type_sch_index_reportTemplate,
    Type_sch_post_reportTemplate,
    Type_sch_put_reportTemplate,
    Type_sch_show_reportTemplate,
    Type_show_reportTemplate,
} from "src/api/tms-scheduling/reportTemplate/types";
import { displayFormattedDate } from "src/utils/date";

export const formatterIndexReports = (
    reports: Type_sch_index_reportTemplate[],
): Type_index_reportTemplate[] => {
    return reports.map((report: Type_sch_index_reportTemplate) => {
        return formatterIndexReport(report);
    });
};

export const formatterIndexReport = (
    report: Type_sch_index_reportTemplate,
): Type_index_reportTemplate => {
    return {
        id: report.id,
        name: report.name,
        createdAt: displayFormattedDate(report.created_at),
        user: report.user.firstName + " " + report.user.lastName,
        enabled: report.enabled,
    };
};

export const formatterShowReport = (
    report: Type_sch_show_reportTemplate,
): Type_show_reportTemplate => {
    return {
        id: report.id,
        name: report.name,
        createdAt: displayFormattedDate(report.created_at),
        user: report.user.firstName + " " + report.user.lastName,
        enabled: report.enabled,
    };
};

export const formatterCreateReport = (
    report: FormikValues,
): Type_sch_post_reportTemplate => {
    return {
        name: report.name,
        file: report.template,
    };
};

export const formatterUpdateReport = (
    report: Type_put_reportTemplate,
): Type_sch_put_reportTemplate => {
    return {
        name: report.name,
        file: report.template,
        enabled: report.enabled,
    };
};
