import { Stack } from "@mui/material";
import { useMemo } from "react";

import {
    mutationUpsertTaskAreaResourceForMatrix,
    useIndexTaskAreaResourcesForMatrix,
} from "src/api/tms-scheduling/taskAreaResources";
import { matrixCustomColumns } from "src/components/Components_Common/matrix/columnDefinitions/matrixCustomColumns";
import { Matrix } from "src/components/Components_Common/matrix/Matrix";
import { getMaxDepth } from "src/components/Components_Scheduling/Matrix/helpers/MatrixSequenceAreasHelpers";
import { getTaskAreaRows } from "src/components/Components_Scheduling/Matrix/helpers/MatrixTaskAreasHelper";
import { MatrixSequenceAreasColumns } from "src/components/Components_Scheduling/Matrix/MatrixSequenceAreas/MatrixSequenceAreasColumns";
import { useUser } from "src/contexts/user";

type Type_Props_MatrixTaskAreaResourcesTask = {
    taskId: number;
    onClose: () => void;
};

export const MatrixTaskAreaResourcesTask = ({
    taskId,
    onClose,
}: Type_Props_MatrixTaskAreaResourcesTask) => {
    ///////////////////////////////////////
    ///           Permission            ///
    ///////////////////////////////////////
    const page = "tasks";
    const { checkPermission, user } = useUser();

    const editable = useMemo(() => {
        return page
            ? checkPermission(page, "create") || checkPermission(page, "update")
            : true;
    }, [page, user]);

    ///////////////////////////////////////
    ///          Fetched data           ///
    ///////////////////////////////////////
    const { data, isFetching, refetch } = useIndexTaskAreaResourcesForMatrix({
        taskId,
    });
    const { mutateAsync } = mutationUpsertTaskAreaResourceForMatrix(taskId);

    const refetchValues = async () => {
        return await refetch();
    };

    const maxDepth = useMemo(
        () => (data?.areas ? getMaxDepth(data?.areas) : 0),
        [data],
    );

    ///////////////////////////////////////
    ///          Columns                ///
    ///////////////////////////////////////
    const areaColumns = MatrixSequenceAreasColumns({
        depth: maxDepth,
        enableOrderEdition: false,
        withSelected: false,
        withOrder: false,
    });

    const customColumns = useMemo(
        () => [
            ...matrixCustomColumns({
                data: data?.dataColumns || [],
                editable,
            }),
        ],
        [isFetching],
    );

    const columns = [...areaColumns, ...customColumns];

    ///////////////////////////////////////
    ///              Rows               ///
    ///////////////////////////////////////
    const dataRows = useMemo(
        () =>
            getTaskAreaRows({
                data: data,
                dataColumns: customColumns,
                maxDepth: maxDepth,
            }),
        [data?.areas, customColumns, maxDepth],
    );

    return (
        <Stack p={4} flexGrow={1}>
            <Matrix
                onClose={onClose}
                columns={columns}
                rows={dataRows}
                refetch={refetchValues}
                mutateUpdate={mutateAsync}
                keysToUpdateSolo={["resource"]}
            />
        </Stack>
    );
};
